import React from 'react';
import './Card.css'

import cv from '../../image/Aditya_CV.pdf'
import { Button } from 'bootstrap';
const Card = () => {
  return (
    <div className="card">
      <h1 className="card-title">The Skills I Have </h1>
      
      <ul className="card-get_list">
        <li className="card-get_list__item">HTML</li>
        <li className="card-get_list__item">Bootstrap 5.0</li>
        <li className="card-get_list__item">JAvaScript</li>
        <li className="card-get_list__item">React JS</li>
        <li className="card-get_list__item">Core Java</li>
        <li className="card-get_list__item">MySQL Database</li>
      </ul>
      <a href={cv} download> <button className="card-main_button">Download CV</button> </a>
      
    </div>
  );
};

export default Card;
