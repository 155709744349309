import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


import './App.css';

import Home from './Component/Home';

function App() {
  return (
    <div className='cover-body' >
     <Home/>
    </div>
  );
}

export default App;
